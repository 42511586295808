<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-8">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">
            {{ lista_campos != "" ? "Editar" : "Criar" }} Arquivo
          </h3>
        </div>
        <form></form>
        <div class="card-body">
          <div class="col-md-12">
            <div class="form-group row">
              <div class="col-md-12">
                <label class="col-md-12">Nome:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.nome"
                  placeholder="Digite o nome..."
                />
              </div>
              <div class="col-md-12">
                <label class="col-md-12">Palavras chaves indexadas:</label>
                <textarea
                  type="text"
                  class="form-control"
                  v-model="form.palavras_chaves"
                  placeholder="Digite a senha..."
                  rows="6"
                />
              </div>
                 <div class="col-md-12">
                <label class="col-md-12">Código caixa armazenamento:</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.cod_armazenamento"
                  placeholder="Digite o codigo no armazemanto fisico..."
                />
              </div>

              <div class="col-md-12">
                <label class="col-md-12">Documento:*</label>
                <input
                  class="form-control"
                  type="file"
                  id="file"
                  ref="file"
                  v-on:change="handleFileUpload()"
                />
              </div>
            </div>

            <div class="row justify-content-end">
              <div class="form-group row">
                <div class="col-md-12 text-center">
                  <button
                    @click.prevent="
                      confirm(lista_campos == '' ? 'cria' : 'edita')
                    "
                    class="btn btn-primary"
                    :disabled="verif"
                  >
                    Salvar
                    <b-spinner
                      v-show="verif"
                      small
                      variant="dark"
                      type="grow"
                      label="Spinning"
                    ></b-spinner>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [fireAlert],
  data() {
    return {
      file: [],
      form: {
        nome: "",
        palavras_chaves: "",
        cod_armazenamento:""
      },

      verif: false,
    };
  },
  created() {
    this.preenxerCampos();
  },
  computed: {
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },

    rota() {
      return this.$store.state.documentos.rota;
    },
    mensagem_alert() {
      return this.$store.state.documentos.mensagem_alert;
    },
  },

  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação ` + tipo + ` um Documento no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo === "cria") this.create();
            if (tipo === "edita") this.update();
          }
        },
      });
    },
    async create() {
      let formData = new FormData();

      this.verif = true;

      formData.append("nome", this.form.nome);

      formData.append("palavras_chaves", this.form.palavras_chaves);
      formData.append("rota", this.rota.rota);
      formData.append("pastas_id", this.rota.pastas_id);

      if (this.form.file) {
        formData.append("file[" + 0 + "]", this.form.file);
      }

      await this.$store.dispatch("documentos/create_documento", formData);
      this.verif = false;
      //  this.$store.dispatch("guardarDados", this.lista_dados_guardados);
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "arquivex",
      });
    },
    async update() {
      let formData = new FormData();

      this.verif = true;
      // for (var i = 0; i < this.file.length; i++) {
      //   let fil = this.file[i];
      formData.append("id", this.form.id);

      formData.append("nome", this.form.nome);

      formData.append("palavras_chaves", this.form.palavras_chaves);
      formData.append("pastas_id", this.form.pastas_id);

      if (this.form.file) {
        formData.append("file[" + 0 + "]", this.form.file);
      }
      // }

      console.log(formData);

      await this.$store.dispatch("documentos/update_documento", formData);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
      });
      this.$router.push({
        name: "viewfile",
        params: { arquivo_id: this.form.id },
      });
    },
    preenxerCampos() {
      if (this.lista_campos != "")
        this.form = {
          id: this.lista_campos.id,
          // caminho: this.lista_campos.file,
          nome: this.lista_campos.nome,
          pastas_id: this.lista_campos.pastas_id,
          palavras_chaves: this.lista_campos.palavras_chaves,
        };
      console.log(this.form);
    },

    handleFileUpload() {
      var b = this.$refs.file.files.length;
      for (var i = 0; i < b; i++) {
        this.form.file = this.$refs.file.files[i];
        //   var a = this.$refs.file.files[i];

        //   var ultima = a.name.substring(a.name.lastIndexOf(".") + 1);
        //   var res = 0;
        //   if (ultima == "jpg") {
        //     this.file.push(this.$refs.file.files[i]);
        //     res = 1;
        //   }
        //   if (ultima == "jpeg") {
        //     this.file.push(this.$refs.file.files[i]);
        //     res = 1;
        //   }
        //   if (ultima == "png") {
        //     this.file.push(this.$refs.file.files[i]);
        //     res = 1;
        //   }
        //   if (ultima == "pdf") {
        //     this.file.push(this.$refs.file.files[i]);
        //     res = 1;
        //   }
        //   if (ultima == "docx") {
        //     this.file.push(this.$refs.file.files[i]);
        //     res = 1;
        //   }
        //   if (ultima == "pptx") {
        //     this.file.push(this.$refs.file.files[i]);
        //     res = 1;
        //   }
        //   if (ultima == "pfx") {
        //     this.file.push(this.$refs.file.files[i]);
        //     res = 1;
        //   }

        //   if (res == 0) {
        //     alert(
        //       "Este não é um documentos valido, Selecione um documentos de imagem do tipo. jpg, jpeg, png, pdf, docx, pptx"
        //     );
        //   }
      }
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>